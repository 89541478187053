'use strict';
var angular;
angular.module('portailWebApp')
  .controller('SupportTicketListController', ['$scope', '$location', '$rootScope', '_', 'Flash', 'TokenRestAngular', 'TokenRestAngularMS', '$timeout', 'uiGridConstants', 'customFilter', 'Monitoring', '$translate', '$window', 'localStorageService', '$routeParams', '$filter', 'moment', 'userService', ($scope, $location, $rootScope, _, Flash, TokenRestAngular, TokenRestAngularMS, $timeout, uiGridConstants, customFilter, Monitoring, $translate, $window, localStorageService, $routeParams, $filter, moment, userService) => {

    $rootScope.initDropdown('.yp-toolbar, .yp-table--dd, .yp-table__toolbar');
    $scope.datePickers = $rootScope.initDropdown(
      '.yp-form__label--datepicker', {
        multi: true,
        closeOut: true
      }
    );
    $rootScope.isAction('ticket_list');
    if (!$rootScope.isAction('create_intervention')) {
      $scope.frozen = true;
    } else {
      $scope.my_basket = true;
    }
    //Flash
    Flash.clear();
    //Layout
    $rootScope.pageClass = '';
    //Title
    $rootScope.subTitle = 'ticket_list';
    $rootScope.helpLink = 'yooportal/support/ticket-gestion.html';

    //Endpoint
    let tk = TokenRestAngularMS,
      ep = {
        licences: tk.all('ladmin/licences'),
        tickets: tk.all('supportv2/tickets'),
        interventions: tk.all('support/interventions'),
        types: tk.all('support/ressources/types'),
        orga: tk.all('support/ressources/organizations'),
        organizations: tk.all('tools/organizations'),
        origins: tk.all('support/ressources/origin'),
        frozen_reasons: tk.all('support/ressources/frozen_reason'),
        impacts: tk.all('support/ressources/impact'),
        criticities: tk.all('support/ressources/criticity'),
        categories: tk.all('support/ressources/category'),
        baskets: tk.all('support/ressources/baskets'),
        actions: tk.all('support/ressources/action'),
        files: tk.all('support/attachments'),
        users: tk.all('ladmin/users'),
        ticket_types: tk.all('support/ressources/ticket_type')
      },
      saveLocal = (key, value) => {
        return localStorageService.set(key, value);
      },
      orgacols = [
        {
          field: 'name',
          displayName: 'name',
          headerCellFilter: 'translate',
          cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS}} <a ng-click="grid.appScope.organization_info(row.entity.id)"><i class="material-icons md-18">info</i></a></div>'
        },
        {
          field: 'town',
          displayName: 'town',
          headerCellFilter: 'translate'
        },
        {
          field: 'zipcode',
          displayName: 'zipcode',
          type: 'number',
          headerCellFilter: 'translate'
        },
        {
          field: 'country',
          displayName: 'country',
          headerCellFilter: 'translate'
        }
      ],
      ticketcols = [
        {
          field: 'id',
          displayName: 'id',
          headerCellFilter: 'translate',
          cellTemplate: '<div class="ui-grid-cell-contents yp-cell_graph-icn" title="{{row.entity.lock_date}}"><i class="material-icons md18" ng-show="row.entity.lock_user_id">lock</i><i class="material-icons md18"  ng-show="grid.appScope.isAction(\'create_intervention\') && row.entity.support2_froze_id  ">alarm_on</i> <i class="material-icons md18 "  style="color:red" ng-if="grid.appScope.isAction(\'create_intervention\') && row.entity.sladanger ">report_problem</i>{{COL_FIELD CUSTOM_FILTERS}}  <pin-it name="ticket" data="row.entity"></pin-it></div>'
        },
        {
          field: 'ticket_type.value',
          displayName: 'ticket_type',
          headerCellFilter: 'translate'
        },
        {
          field: 'organization.name',
          displayName: 'organization',
          headerCellFilter: 'translate',
          cellTemplate: '<div class="ui-grid-cell-contents orgaFormation ng-class: row.entity.organization.certified ? \'relative membershipButton\' : \'\'"><i class="material-icons icon-formation" ng-if="row.entity.formation" ng-bind-html="\'person_pin\'"></i><img ng-if="row.entity.organization.certified" class="badgeCertified py-1" src="../../images/organisations/certified.svg" /><span>{{ row.entity.organization.name }}</span></div>'
        },
        {
          field: 'licence.seller.name',
          displayName: 'reseller',
          headerCellFilter: 'translate',
          visible: false,
          cellTemplate: '<div class="ui-grid-cell-contents ng-class: row.entity.licence.seller.certified ? \'relative membershipButton\' : \'\'"><img ng-if="row.entity.licence.seller.certified" class="badgeCertified py-1" src="../../images/organisations/certified.svg" />{{ row.entity.licence.seller.name }}</div>'
        },
        {
          field: 'licence.licence',
          displayName: 'licence',
          headerCellFilter: 'translate'
        },
        {
          field: 'contracts',
          displayName: 'contracts',
          headerCellFilter: 'translate'
        },
        {
          field: 'category.value',
          displayName: 'category',
          headerCellFilter: 'translate'
        },
        {
          field: 'impact.value',
          displayName: 'impact',
          headerCellFilter: 'translate',
          visible: false
        },
        {
          field: 'frozen_reason.value',
          displayName: 'frozen_reason',
          headerCellFilter: 'translate'
        },
        {
          field: 'scale',
          displayName: 'scale_to_management_cs',
          headerCellFilter: 'translate',
          cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS | translate}}</div>',
          filterHeaderTemplate: customFilter.bool.template,
          filter: {
            options: customFilter.bool.filterBool,
            condition: customFilter.bool.filterStatus
          }
        },
        {
          field: 'priority',
          displayName: 'priority',
          headerCellFilter: 'translate',
          type: 'number',
          visible: false
        },
        {
          field: 'criticity.value',
          displayName: 'criticity',
          headerCellFilter: 'translate',
          visible: false
        },
        {
          field: 'origin.value',
          displayName: 'origin',
          headerCellFilter: 'translate',
          visible: false
        },

        {
          field: 'created_at',
          displayName: 'created_at',
          headerCellFilter: 'translate',
          enableFiltering: true,
          cellFilter: 'readbleDate',
          visible: false,
          filters: [
            {
              condition: customFilter.date.isBefore
            },
            {
              condition: customFilter.date.isAfter
            }
          ],
          filterHeaderTemplate: customFilter.date.template
        },
        {
          field: 'closure_date',
          displayName: 'closure_date',
          headerCellFilter: 'translate',
          cellFilter: 'readbleDate',
          enableFiltering: true,
          visible: false,
          filters: [
            {
              condition: customFilter.date.isBefore
            },
            {
              condition: customFilter.date.isAfter
            }
          ],
          filterHeaderTemplate: customFilter.date.template
        },
        {
          field: 'basket.name',
          displayName: 'baskets',
          headerCellFilter: 'translate'
        },
        {
          field: 'rma',
          type: 'number',
          displayName: 'rma',
          headerCellFilter: 'translate',
          enableFiltering: true,
          visible: false
        },
        {
          field: 'following_date',
          displayName: 'following_date',
          headerCellFilter: 'translate',
          cellFilter: 'readbleDate',
          enableFiltering: true,
          filters: [
            {
              condition: customFilter.date.isBefore
            },
            {
              condition: customFilter.date.isAfter
            }
          ],
          filterHeaderTemplate: customFilter.date.template
        },
        {
          field: 'username',
          displayName: 'creator',
          headerCellFilter: 'translate',
          cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD ? COL_FIELD : "system" | translate}}</div>',
          visible: false
        }
      ];
    $scope.tickets = [];
    $scope.inputs = {};
    $scope.ready = {};
    $scope.isAdminOrSupport = userService.isAdminOrSupport();
    //refresh
    $scope.refresh = {
      tickets: () => {
        let filters = {
          'organization_id[]': []
        },
          organizations = $scope.gridApi1.selection.getSelectedRows();
        filters.status = $scope.status ? $scope.status : null;
        filters.mine = $scope.mine ? $scope.mine : null;
        filters.frozen = $scope.frozen ? $scope.frozen : null;
        filters.my_basket = $scope.my_basket ? $scope.my_basket : null;
        filters.basket_id = $scope.basket_id ? $scope.basket_id : null;
        filters.licence_key = $scope.licence_key ? $scope.licence_key : null;

        if ($scope.date_beg !== 0 && moment($scope.date_beg).isValid() && angular.isDefined($scope.date_beg)) {
          filters.date_beg = moment($scope.date_beg).format('YYYY-MM-DDTHH:mm');
        }

        if ($scope.date_end && moment($scope.date_end).isValid() && angular.isDefined($scope.date_end)) {
          filters.date_end = moment($scope.date_end).format('YYYY-MM-DDTHH:mm');
        }

        if (organizations.length > 0 && organizations.length <= 100) {
          organizations.forEach(v => {
            filters['organization_id[]'].push(v.id);
          });
        } else if (organizations.length > 100) {
          $scope.tools.clearAll();
          Flash.show('no_more_selection');
        }

        ep.tickets.getList(filters).then(function (data) {
          if (userService.isAdminOrSupport() && !ticketcols.find((col) => col.field === 'kb_article')) {
            ticketcols.push({
              field: 'kb_article',
              displayName: 'need_to_be_kb_article',
              headerCellFilter: 'translate',
              cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS | translate}}</div>',
              filterHeaderTemplate: customFilter.bool.template,
              filter: {
                options: customFilter.bool.filterBool,
                condition: customFilter.bool.filterStatus
              }
            })
          }
          angular.forEach(data, row => {
            row.lock_date = $filter('utc2local')(row.lock_date) || null;
            row.kb_article = !!row.kb_article
            row.scale = !!row.scale

            if (row.licence && (row.git > row.slagit || row.grt > row.slagrt)) {
              row.sladanger = true;
            } else {
              row.sladanger = false;
            }
            if (row.lock_date && row.lock_date.unix() > moment().unix()) {
              row.lock_date = row.lock_date.fromNow();
            } else {
              row.lock_date = null;
              row.lock_user_id = null;
            }

            if (row.status !== 1) {
              row.closure_date = $filter('utc2local')(row.updated_at);
            } else {
              row.closure_date = null;
            }
          });

          $scope.countOld = _.countBy(data, row => {
            let tdate = moment(row.created_at),
              r;
            if (tdate.isBefore(moment().subtract(14, 'days'))) {
              r = 'two';
            } else if (tdate.isBefore(moment().subtract(7, 'days'))) {
              r = 'one';
            }
            return r;
          });

          $scope.count = _.countBy(data, row => {
            let r;
            if (!row.basket || !row.following_date) {
              r = 'virgin';
            }
            return r;
          });

          data.map(x => {
            x.following_date = $filter('utc2local')(x.following_date);
            x.planning_date = $filter('utc2local')(x.planning_date);
            x.updated_at = $filter('utc2local')(x.updated_at);
            x.created_at = $filter('utc2local')(x.created_at);
            return x;
          });
          $scope.tickets = data;


          $scope.sessionSavePersistence();
        });
      },
      organizations: () => {
        var filters = {},
          storage = localStorageService.get(ep.orga.route);

        if ($scope.relation_id) {
          filters.relation_id = $scope.relation_id;
        }

        if (storage && !$scope.relation_id) {
          $scope.inputs.orga = storage;
          $scope.ready.orga = true;
        }
        ep.organizations.getList(filters).then(orgas => {
          $scope.inputs.orga = orgas;
          if (!$scope.relation_id) {
            saveLocal(ep.orga.route, $scope.inputs.orga);
          }
          $scope.ready.orgas = true;
        });

      },
      baskets: () => {
        ep.baskets.getList().then(data => {
          $scope.inputs.baskets = _.map(data, v => {
            return v;
          });
          $scope.ready.baskets = true;
          $translate('none').then(msg => {

            if (!$scope.inputs.hasOwnProperty('baskets') || !$scope.inputs.baskets) {
              $scope.inputs.baskets = [];
            }
            $scope.inputs.baskets.unshift({
              id: null,
              name: msg
            });
            $scope.basket_id = 0;
          });
        });
      }
    };

    $scope.refresh.organizations();
    $scope.refresh.baskets();

    $scope.tools = {
      selectAll: () => {
        $scope.gridApi1.selection.selectAllVisibleRows();
      },
      clearAll: () => {
        $scope.gridApi1.selection.clearSelectedRows();
      },
      clearIt: item => {
        $scope.gridApi1.selection.unSelectRow(item);
      }
    };


    $scope.gridOptions1 = Monitoring.setGridOption('inputs.orga', orgacols, true);
    $scope.gridOptions1.onRegisterApi = gridApi => {
      gridApi.selection.on.rowSelectionChanged($scope, () => {
        $scope.refresh.tickets();
        $scope.sessionSavePersistence();
      });
      gridApi.selection.on.rowSelectionChangedBatch($scope, () => {
        $scope.refresh.tickets();
        $scope.sessionSavePersistence();
      });
      gridApi.core.on.filterChanged($scope, () => {
        $scope.sessionSavePersistence();
      });
      gridApi.core.on.sortChanged($scope, () => {
        $scope.sessionSavePersistence();
      });
      gridApi.core.on.columnVisibilityChanged($scope, () => {
        $scope.sessionSavePersistence();
      });
      $scope.gridApi1 = gridApi;
    };

    $scope.filters = [];
    // Monitoring service
    Monitoring.refreshFilters('ticket_list', data => {
      $scope.filters = data;
      $timeout(() => {
        if (localStorageService.get('persistence_support')) {
          $scope.sessionGetPersistence();
        } else {
          if (data.length > 0) {
            $scope.loadFilter(data[0].id);
          }
          $scope.refresh.tickets();
        }
        if ($routeParams.filterType && $routeParams.filterValue) {
          $timeout(() => {
            $scope.filterColumnLicence($routeParams.filterValue);
          }, $scope.tickets);
        }
        //Filters
        if ($routeParams.typefiltre) {
          switch ($routeParams.typefiltre) {
            case 'interventions':
              if ($routeParams.param1 === 'virgin') {
                $timeout(() => {
                  $scope.filterColumnVirgin();
                }, $scope.tickets);
              } else if ($routeParams.param1 === 'overstayed') {
                $timeout(() => {
                  $scope.filterColumnFollowing();
                }, $scope.tickets);
              }
              break;
            case 'ages':
              $timeout(() => {
                $scope.filterColumnOldByDates($routeParams.param1, $routeParams.param2);
              }, $scope.tickets);
              break;
            default:
              $timeout(() => {
                $scope.filterColumnBasket($routeParams.param1);
              }, $scope.tickets);
          }
        }
        //
      }, $scope.gridApi);
    });
    $scope.loadIt = () => {
      Monitoring.loadFilters('ticket_list', id => {
        $scope.loadFilter(id);
      });
    };

    $scope.loadFilter = id => {
      Monitoring.loadFilter(id, data => {
        //if filters  grid1?
        let columns = $scope.gridApi.saveState.save();

        angular.forEach(columns.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid.columns[i].filters[j].term = filter.term;
            }
          });
        });
        //if filters  grid1?
        columns = $scope.gridApi1.saveState.save();

        if (data.data.gridOrga) {
          angular.forEach(columns.columns, (item, i) => {
            angular.forEach(item.filters, (filter, j) => {
              if (filter.term) {
                data.data.gridOrga.columns[i].filters[j].term = filter.term;
              }
            });
          });
        }
        $scope.status = data.data.status;
        $scope.mine = data.data.mine;
        $scope.basket_id = data.data.basket_id;

        if (data.data.date_beg) {
          $scope.date_beg = moment(data.data.date_beg).toDate();
        }
        if (data.data.date_end) {
          $scope.date_end = moment(data.data.date_end).toDate();
        }
        if (data.data.grid) {
          $scope.gridApi.saveState.restore($scope, data.data.grid);
        }
        if (data.data.gridOrga) {
          $scope.gridApi1.saveState.restore($scope, data.data.gridOrga);
        }
        $scope.refresh.tickets();
      });
    };
    $scope.saveIt = () => {
      Monitoring.saveFilter('ticket_list', {
        grid: $scope.gridApi.saveState.save(),
        gridOrga: $scope.gridApi1.saveState.save(),
        selectedOrga: $scope.gridApi1.selection.getSelectedRows(),
        status: $scope.status,
        mine: $scope.mine,
        basket_id: $scope.basket_id,
        date_beg: $scope.date_beg,
        date_end: $scope.date_end
      });
    };

    //SESSION PERSISTENCE
    $scope.sessionSavePersistence = () => {
      let data = {
        gridOrga: $scope.gridApi1.saveState.save(),
        grid: $scope.gridApi.saveState.save(),
        status: $scope.status,
        mine: $scope.mine,
        basket_id: $scope.basket_id,
        date_beg: $scope.date_beg,
        date_end: $scope.date_end,
        frozen: $scope.frozen,
        my_basket: $scope.my_basket
      };
      if (data.grid.columns.length || data.gridOrga.columns.length) {
        let tostock = angular.toJson(data);
        localStorageService.set('persistence_support', tostock);
      }
    };

    $scope.updateDate = key => {
      $scope.datePickers.forEach(v => {
        v.close(0);
      });
      $scope[key] = moment($scope[key]).toDate();
    };

    $scope.sessionGetPersistence = () => {
      let fromstock = localStorageService.get('persistence_support');
      if (fromstock) {
        $scope.status = fromstock.status;
        $scope.mine = fromstock.mine;
        $scope.basket_id = fromstock.basket_id;
        $scope.frozen = fromstock.frozen;
        $scope.my_basket = fromstock.my_basket;
        if (fromstock.date_beg) {
          $scope.date_beg = moment(fromstock.date_beg).toDate();
        }
        if (fromstock.date_end) {
          $scope.date_end = moment(fromstock.date_end).toDate();
        }

        if (fromstock.gridOrga) {
          $timeout(() => {
            $scope.gridApi1.saveState.restore($scope, fromstock.gridOrga);
          }, $scope.gridApi);
        }
        //if filters  grid1?
        let columns = $scope.gridApi.saveState.save();
        angular.forEach(columns.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              fromstock.grid.columns[i].filters[j].term = filter.term;
            }
          });
        });
        if (fromstock.grid) {
          $timeout(() => {
            $scope.gridApi.saveState.restore($scope, fromstock.grid);
          }, $scope.gridApi);
        }

      }
      $scope.refresh.tickets();
    };

    //SESSION PERSISTENCE

    $scope.organization_info = organization_id => {
      Monitoring.organization(organization_id);
    };


    $scope.gridOptions = Monitoring.setGridOption('tickets', ticketcols, false, true, 'high');
    $scope.gridOptions.rowTemplate = '<a ng-href="/support/ticketview/{{row.entity.id}}" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ui-grid-cell ></a>';
    $scope.gridOptions.onRegisterApi = gridApi => {
      $scope.gridApi = gridApi;
      gridApi.core.on.filterChanged($scope, () => {
        $scope.sessionSavePersistence();
      });
      gridApi.core.on.sortChanged($scope, () => {
        $scope.sessionSavePersistence();
      });
      gridApi.core.on.columnVisibilityChanged($scope, () => {
        $scope.sessionSavePersistence();
      });
    };

    $scope.filterColumnLicence = val => {
      let col = _.find($scope.gridApi.grid.columns, {
        field: 'licence.licence'
      });
      if (col) {
        col.filters[0].term = val;
      }
      $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };
    $scope.filterColumnOld = week => {
      let days = parseInt(week, 10) * 7,
        old = moment().subtract(days, 'days').format('YYYY-MM-DD HH:mm'),
        col = _.find($scope.gridApi.grid.columns, {
          field: 'created_at'
        });
      if (col) {
        col.filters[0].term = old;
      }
      $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };

    $scope.filterColumnOldByDates = (date1, date2) => {
      let col = _.find($scope.gridApi.grid.columns, {
        field: 'created_at'
      }),
        d1 = moment(date1).subtract(1, 'minutes').format('YYYY-MM-DD HH:mm'),
        d2 = moment(date2).add(1, 'minutes').format('YYYY-MM-DD HH:mm');
      if (col) {
        col.filters[0].term = d2;
        col.filters[1].term = d1;
      }
    };

    $scope.filterColumnFollowing = () => {
      let old = moment().format('YYYY-MM-DD HH:mm'),
        col = _.find($scope.gridApi.grid.columns, {
          field: 'following_date'
        });
      if (col) {
        col.filters[0].term = old;
      }
      $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };

    $scope.filterColumnBasket = term => {
      let col = _.find($scope.gridApi.grid.columns, {
        field: 'basket.name'
      });
      term = term ? term : 'null';
      if (col) {
        col.filters[0].term = term;
      }
      $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };

    $scope.filterColumnVirgin = term => {
      let col = _.find($scope.gridApi.grid.columns, {
        field: 'basket.name'
      });
      term = term ? term : 'undefined';
      if (col) {
        col.filters[0].term = term;
      }
      $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };
    //init
    $scope.status = 1;
  }]);
