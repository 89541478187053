((function () {

  angular.module('portailWebApp')
    .factory('userService', userService);

  userService.$inject = ['$rootScope', 'apiRest', 'TokenRestAngular', 'localStorageService', 'Acl', '$location', '$log', 'Flash', '$filter'];

  function userService($rootScope, apiRest, TokenRestAngular, localStorageService, Acl, $location, $log, Flash, $filter) {

    const ctrl = this,
      accountsRoute = 'accounts',
      authLogsRoute = 'accounts/logs',
      tokenRoute = 'oauth/access_token',
      tokenSmsRoute = 'oauth/access_token/sms';

    return {
      route: accountsRoute,
      checkAuth: checkAuth,
      isAdmin: isAdmin,
      isSupport: isSupport,
      isSMBSupport: isSMBSupport,
      isAuth: isAuth,
      tryToken: tryToken,
      tryLogin: tryLogin,
      logout: logout,
      getUser: getUser,
      setUser: setUser,
      getAuthHistory: getAuthHistory,
      isAdminOrSupport: isAdminOrSupport,
      user: () => ctrl.user
    };

    ////////////////////////////////////////////////////////////
    function isSupport() {
      return Acl.isAction('create_intervention');
    }

    function isSMBSupport() {
      let currMs = localStorageService.get('currMs');
      let ms = ctrl.user.membership.find(m => m.id === currMs);
      return ctrl.user && ms && ms.right_id === 5;
    }

    function manageError(res) {
      let message = false;

      if (res.data && res.data.error && res.data.error.message) {
        message = res.data.error.message;
      }
      localStorage.clear('access_token');
      Flash.show(message);
      $rootScope.$broadcast('TokenChanged');
    }

    function tryToken(credentials) {
      return apiRest(tokenSmsRoute)
        .post(credentials)
        .then(res => {
          $rootScope.xhr = true;
          localStorageService.set('access_token', res.access_token);
          $rootScope.wantedUrl = null;
          $rootScope.$broadcast('TokenChanged');
          $rootScope.xhr = false;
          localStorageService.set('access_token', res.access_token);
          return getUser()
            .then(setUser)
            .then(() => {
              $location.url('/main');
            })
            .catch(manageError);
        }).catch(manageError);
    }

    function tryLogin(credentials, url) {
      return apiRest(tokenRoute)
        .post(credentials)
        .then(res => {
          if (res === 'phone_check_needed') {
            localStorage.clear('access_token');
            return 'need_token';
          }

          $rootScope.xhr = true;
          localStorageService.set('access_token', res.access_token);
          $rootScope.wantedUrl = null;
          $rootScope.$broadcast('TokenChanged');
          $rootScope.xhr = false;

          return getUser()
            .then(setUser)
            .then(() => {
              $location.url(url || '/main');
            });
        })
        .catch(manageError);
    }

    function getAuthHistory() {
      return apiRest(authLogsRoute, true).getList('')
        .then(logs => logs.plain().map(x => {
          x.created_at = $filter('utc2local')(x.created_at);
          try {
            x.context = angular.fromJson(x.context);
            x.jsonContext = true;
          } catch (e) {
            $log.log('legacy context format', x.context);
            delete x.context;
          }
          return x;
        }));
    }

    function logout() {
      localStorageService.clearAll();
      $rootScope.xhr = true;
      $rootScope.currMs = {};
      delete ctrl.user;
      Acl.destroy();
      $location.url('/login');
    }

    function isAdmin(forceLogout) {

      if ((!ctrl.user || !ctrl.user.admin) && forceLogout) {
        //replace with error service after

        logout();
        return false;
      }
      return ctrl.user && ctrl.user.admin;
    }

    function isAdminOrSupport() {
      if (ctrl.user) {
        let currMs = localStorageService.get('currMs');
        let ms = ctrl.user.membership.find(m => m.id === currMs)
  
        return isAdmin() || Acl.canIDoIN({id: ms.organization_id}, 'support_monitoring_criticalities')
      }
      return false 
    }

    function checkAuth(event, callback) {
      /**
             * if we have a access_token && the callback
             * we execute the callback directly (error handler)
             * without checking any further
             */
      let ret = null;
      if (!localStorageService.get('access_token') && callback) {
        //event.preventDefault();
        ret = callback({status: 401});

      } else {
        //event.preventDefault();
        /**
                 * if apiAuth is set to true, we verify the user
                 * on the server side
                 */
        ret = getUser()
          .then(setUser)
          .catch(() => false);
      }
      return ret;
    }

    function isAuth() {
      return !!localStorageService.get('access_token');
    }

    function setUser(u) {
      let currMs = localStorageService.get('currMs');
      $rootScope.chooseRightLanguage(u.i18nlanguage_id);
      if (currMs && u.membership && u.membership.length !== 0) {
        $rootScope.changeCurrentMembership(u.membership.find(m => m.id === currMs));
      } else if (!currMs && u.membership && u.membership.length === 1) {
        $rootScope.changeCurrentMembership(u.membership[0]);
      } else if (!currMs && u.membership && u.membership.length !== 0) {
        $rootScope.changeCurrentMembership(u.membership[0], true);
      }
      ctrl.user = u;
      return u;
    }

    function getUser() {
      return TokenRestAngular.one(accountsRoute).get();
    }
  }

})());
